import React from "react";
import "../Home.css"
function Winnlist() {
    return ( 
        <>
         <div className="win-list">
              <span>8</span>
              <span>27</span>
              <span>3</span>
              <span>24</span>
              <span>27</span>
              <span>8</span>
              <span>27</span>
              <span>7</span>
              <span>8</span>
            </div>
        </>
     );
}

export default Winnlist;