import React from "react";
import "../Home.css";
import chip from "../Image/chip_black.png";
import { useState } from "react";
function ProfilePop() {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <>
      <div className="profile-main">
        <div className="profile-left">
          <div className="profile-img">
            <div className="profileupload">
              <input className="proinp" type="file" onChange={handleChange} />
              <img className="pprl" src={file} />
              <div className="df">
                <i class="fa-solid fa-camera"></i>
              </div>
            </div>
          </div>
          <div className="profile-contant3">
            <h2>Welcome!</h2>
            <p>
              Join the Roulette with Google to change profile picture & and save
              scores across devices.
            </p>
          </div>
        </div>
        <div className="profile-right">
          <div className="plyr-contant">
            <h3>
              Player_14535363836 <i class="fa-solid fa-pen"></i>
            </h3>
            <div className="earn">
              <img src={chip} alt="chip" /> <p>20,00</p>
            </div>
            <div className="plyr-rat">
              <i class="fa-solid fa-star"></i>
              <h6>1</h6>
              <span>65.5%</span>
            </div>
          </div>
          <div className="sign-btn">
            <button type="button" className="login-with-google-btn">
              Sign in with Google
            </button>
          </div>
          <p style={{color:"black", position:"absolute", bottom:"0"}}>
            Login is Required to Play Multiplayer Tables, Save & Transfer
            scores, Access LeaderBoards. We care about your data privacy &
            security. <span style={{color:"blue"}}>Tap here & launch Privacy Policy to see What data we
            collect & use.</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default ProfilePop;
