import React from "react";
function Charts() {
  return (
    <>
    <div className="charts">
    <div className="charts-main">
        <div className="charts-head">
          <h3>NUMBER STATISTIC</h3>
        </div>
        <div className="chart-inner">
          <div className="charts-label">
            <div className="lab-1">
              <div className="red-black">
                <div className="red">
                  <span>RED</span>
                  <span>
                    <p>51.4%</p>
                  </span>
                </div>
                <div className="black">
                  <span>BLACK</span>
                  <span>
                    <p>45.4%</p>
                  </span>
                </div>
              </div>
            </div>
            {/* even */}
            <div className="lab-1">
              <div className="even">
                <div className="red">
                  <span>EVEN</span>
                  <span>
                    <p>51.4%</p>
                  </span>
                </div>
                <div className="black">
                  <span>ODD</span>
                  <span>
                    <p>45.4%</p>
                  </span>
                </div>
              </div>
            </div>
            {/* even odd */}
            <div className="lab-1">
              <div className="even">
                <div className="red">
                  <span>1-18</span>
                  <span>
                    <p>51.4%</p>
                  </span>
                </div>
                <div className="black">
                  <span>19-36</span>
                  <span>
                    <p>45.4%</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="charts-table">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="number-lab-main">
            <div className="number-lab">
            <div className="labb">
                <p>0</p>
                <span>55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
               <p>1</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
               <p>2</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                 <p>3</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>4</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>5</p>
                <span className="redlabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
               <p>6</p>
                <span className="blacklabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
               <p>7</p>
                <span className="redlabel">55</span>
                <p>4.5</p>  
              </div>
              <div className="labb">
               <p>8</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>  
              </div>
              <div className="labb">
               <p>9</p>
                <span className="redlabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
               <p>10</p>
                <span className="blacklabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
                <p>11</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>12</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>  
              </div>
              <div className="labb">
                <p>13</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                 <p>14</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                 <p>15</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
               <p>16</p>
                <span className="blacklabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
                 <p>17</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>18</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
               <p>19</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
              <p>20</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>21</p>
                <span className="redlabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
                <p>22</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>  
              </div>
              <div className="labb">
                 <p>23</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
               <p>24</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                 <p>25</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>26</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                 <p>27</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>28</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>29</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>30</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>31</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                 <p>32</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>   
              </div>
              <div className="labb">
              <p>33</p>
                <span className="redlabel">55</span>
                <p>4.5</p> 
              </div>
              <div className="labb">
                <p>34</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
                <p>35</p>
                <span className="redlabel">55</span>
                <p>4.5</p>
              </div>
              <div className="labb">
              <p>36</p>
                <span className="blacklabel">55</span>
                <p>4.5</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Charts;
