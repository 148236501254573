import React from "react";
import "../Home.css"
function Showcoins() {
    return ( 
        <>
        <div className="coin" ></div>
        </>
     );
}

export default Showcoins;