import React, { useState} from "react";
import "../Home.css";
import solobg from "../Image/bg13.jpg";
import coin1 from "../Image/coin4.png";
import coin2 from "../Image/coin2.png";
import coin3 from "../Image/coin3.png";
import coin4 from "../Image/coin4.png";
import coin5 from "../Image/coin5.png";
import coin6 from "../Image/icon6.png";
import coin7 from "../Image/icon7.png";
import Plyrinfo from "./Plyrinfo";
import Table from "./Table";
import Spinbtn from "./Spinbtn";
import Showcoins from "./Showcoins";
import Clearcoins from "./Clearcoin";
import roll from "../Image/roll.png";

import Luckybord from "./Luckybord";

function Solo() {
  const [spin, setspin] = useState(false);
  const [roll1, setRoll1] = useState(false);

  function spinn() {
    setspin((current) => !current);
    setRoll1((current) => !current);
  
      setTimeout(() => {
        setRoll1(false);
        setspin(false);
      }, 4000);      
   
    
  }
  const [coins, Setcoins] = useState(false);

  function showcoins() {
    Setcoins((current) => !current);
  }
 


  return (
    <>
      <div className="solo-main">
        <div className="solo-inner">
          <div className={spin ? "spinbg" : "logo-bg"}>
            <img src={solobg} alt="solobg" />
            <div>
              <Table />
            </div>
            <div onClick={spinn}>
              <Spinbtn />
            </div>
            <div className="french">
              <h5>FRENCH</h5>
            </div>
            <div className="clear-coin">
              <div onClick={showcoins}>
                <Showcoins />
              </div>
              <div>
                <Clearcoins />
              </div>
            </div>
            <div>
              <Plyrinfo />
            </div>
           
            <div>
              <Luckybord />
            </div>
            <div className={roll1 ? "ofroll" : "rollwhile"}>
          <img src={roll} alt="roll" />
        </div>
          </div>
          
        </div>
       
      
        <div className={coins ? "coinnns-act" : "coinnns"}>
          <div className="coin-main">
            <div className="coins coin1">
              <img src={coin1} alt="coin1" />
            </div>
            <div className="coins coin2">
              <img src={coin2} alt="coin1" />
            </div>
            <div className="coins coin3">
              <img src={coin3} alt="coin1" />
            </div>
            <div className="coins coin4">
              <img src={coin4} alt="coin1" />
            </div>
            <div className="coins coin5">
              <img src={coin5} alt="coin1" />
            </div>
            <div className="coins coin6">
              <img src={coin6} alt="coin1" />
            </div>
            <div className="coins coin7">
              <img src={coin7} alt="coin1" />
            </div>
            <div className="coins coin6">
              <img src={coin6} alt="coin1" />
            </div>
            <div className="coins coin4">
              <img src={coin4} alt="coin1" />
            </div>
            <div className="coins coin2">
              <img src={coin2} alt="coin1" />
            </div>
            <div className="coins coin7">
              <img src={coin7} alt="coin1" />
            </div>
            <div className="coins coin2 " onClick={showcoins}>
              <img src={coin2} alt="coin1" />
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
}

export default Solo;
