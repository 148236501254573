import React from "react";
import '../Home.css'
import Winnlist from "./Winlist";
function Table() {

  const coilclick =(e)=>{
    console.log(e.currentTarget.innerText);
  }
    return (  
        <>
         <div className="right">
              <div class="container-first">
                <div class="zero-item" onClick={coilclick}>
                  <div class="value">0</div>
                </div>
                <div class="red-item" onClick={coilclick}>
                  <div class="value">1</div>
                </div>
                <div class="black-item" onClick={coilclick}>
                  <div class="value">2</div>
                </div>
                <div class="red-item" onClick={coilclick}>
                  <div class="value">3</div>
                </div>
                <div class="black-item">
                  <div class="value">4</div>
                </div>
                <div class="red-item">
                  <div class="value">5</div>
                </div>
                <div class="black-item">
                  <div class="value">6</div>
                </div>
                <div class="red-item">
                  <div class="value">7</div>
                </div>
                <div class="black-item">
                  <div class="value">8</div>
                </div>
                <div class="red-item">
                  <div class="value">9</div>
                </div>
                <div class="black-item">
                  <div class="value">10</div>
                </div>
                <div class="black-item">
                  <div class="value">11</div>
                </div>
                <div class="red-item">
                  <div class="value">12</div>
                </div>
                <div class="black-item">
                  <div class="value">13</div>
                </div>
                <div class="red-item">
                  <div class="value">14</div>
                </div>
                <div class="black-item">
                  <div class="value">15</div>
                </div>
                <div class="red-item">
                  <div class="value">16</div>
                </div>
                <div class="black-item">
                  <div class="value">17</div>
                </div>
                <div class="red-item">
                  <div class="value">18</div>
                </div>
                <div class="red-item">
                  <div class="value">19</div>
                </div>
                <div class="black-item">
                  <div class="value">20</div>
                </div>
                <div class="red-item">
                  <div class="value">21</div>
                </div>
                <div class="black-item">
                  <div class="value">22</div>
                </div>
                <div class="red-item">
                  <div class="value">23</div>
                </div>
                <div class="black-item">
                  <div class="value">24</div>
                </div>
                <div class="red-item">
                  <div class="value">25</div>
                </div>
                <div class="black-item">
                  <div class="value">26</div>
                </div>
                <div class="red-item">
                  <div class="value">27</div>
                </div>
                <div class="black-item">
                  <div class="value">28</div>
                </div>
                <div class="black-item">
                  <div class="value">29</div>
                </div>
                <div class="red-item">
                  <div class="value">30</div>
                </div>
                <div class="black-item">
                  <div class="value">31</div>
                </div>
                <div class="red-item">
                  <div class="value">32</div>
                </div>
                <div class="black-item">
                  <div class="value">33</div>
                </div>
                <div class="red-item">
                  <div class="value">34</div>
                </div>
                <div class="black-item">
                  <div class="value">35</div>
                </div>
                <div class="red-item rd">
                  <div class="value">36</div>
                </div>
                <div class="column-item ">
                  <div class="value">2-1</div>
                </div>
                <div class="column-item">
                  <div class="value">2-1</div>
                </div>
                <div class="column-item brt">
                  <div class="value">2-1</div>
                </div>
              </div>
              <div class="container-second">
                <div class="doz-item">
                  <div>1st 12</div>
                </div>
                <div class="doz-item">
                  <div>2nd 12</div>
                </div>
                <div class="doz-item br">
                  <div>3rd 12</div>
                </div>
              </div>
              <div class="container-third">
                <div class="outside-section br1">
                  <div>1-18</div>
                </div>
                <div class="outside-section">
                  <div>EVEN</div>
                </div>
                <div class="outside-section">
                  <div>
                    <div class="rhomb-red"></div>
                  </div>
                </div>
                <div class="outside-section">
                  <div>
                    <div class="rhomb-black"></div>
                  </div>
                </div>
                <div class="outside-section">
                  <div>ODD</div>
                </div>
                <div class="outside-section br br2">
                  <div>19-36</div>
                </div>
              </div>
              <div>
              <Winnlist />
            </div>
            </div>
        </>
    );
}

export default Table;