import React from "react";
import '../Home.css'
function Popup() {
    return ( 
        <>
         <div className="popup1-inner">
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left2">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right2">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left2">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right2">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left2">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right2">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left2">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right2">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left2">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right2">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        <div className="popup-chip-main">
                            <div className="pop-chip-box">
                                <div className="pop-chip-left2">
                                <h4>15M</h4>
                                <h4>CHIPS</h4>
                                </div>
                                <div className="pop-chip-right2">
                                <h6>150000000 Chips</h6>
                                <h6>100 & Get 15 Million Chips Pack $ Remove Ads Forever</h6>
                                </div>
                            </div>
                            <div className="pop-last">
                               <span>1800.00</span> <h6>900.00</h6>
                            </div>
                           
                        </div>
                        
                     </div>
        </>
     );
}

export default Popup;