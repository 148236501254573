
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Solo from './Component/Solo/Board';
import Home from './Component/Home/Home';
import Charts from './Component/Solo/charts';
// import Live from './Component/Live/Live';




function App() {
  return (
    <div className="App">
      {/* <Home/> */}
      {/* <Solo/> */}

      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Solo' element={<Solo/>}/>
        <Route path='/stastic' element={<Charts/>}/>
        {/* <Route path='/live' element={<Live/>}/> */}
      </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
