import React from "react";
import "../Home.css"
function Clearcoins() {
    return ( 
        <>
         <div className="clear">
                <span>
                  <i class="fa-solid fa-share"></i>
                </span>
                <h3>CLEAR</h3>
              </div>
        </>
     );
}

export default Clearcoins;