import React from "react";
import "../Home.css"
function Spinbtn() {
    return ( 
        <>
         <div className="spin-btn">
              <div className="bet">
                <span className="sbet">
                  <p>B</p>
                  <p>E</p>
                  <p>T</p>
                </span>
                <span className="sbet2">
                
                  <h3>0</h3>
                </span>
              </div>
              <button >SPIN</button>
            </div>
        </>
     );
}

export default Spinbtn;