import React, { useState } from "react";
import im1 from '../Image/icon1.png'
import im2 from '../Image/icon2.png'
import im3 from '../Image/icon3.png'
import im4 from '../Image/icon4.png'
import chip from '../Image/chip_black.png'
import '../Home.css'
import { Link } from "react-router-dom";
import Popup from "./Popup";
import ProfilePop from "./profilepop";




function Home() {
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const [open, setOpen] = useState(false);

    const openPopup2 = () => {
        setOpen(true);
    };

    const closePopup2 = () => {
        setOpen(false);
    };


    const [profile, setprofile] = useState(false);

    const openprofile = () => {
        setprofile(true);
    };

    const closeprofile = () => {
        setprofile(false);
    };

    return (
        <div className="home-main">
            <div className="container">
                {/* <div className="bg">
                <img src={bg}alt="bg"/>
            </div> */}
                <div className="game-box-main">
                    <Link to="/Solo">
                        <div className="game-box">
                            <div className="rr">
                                <div className="icon">
                                    <img src={im1} alt="im1" />

                                </div>
                                <h2 className="solo">SOLO</h2>
                            </div>
                        </div></Link>
                    <Link to="">
                        <div className="game-box" onClick={openPopup2}>
                            <div className="rr">
                                <div className="icon">
                                    <img src={im2} alt="im2" />

                                </div>
                                <h2 className="live">Live</h2>
                            </div>
                        </div>
                    </Link>

                    <div className="game-box">
                        <div className="rr">
                            <div className="icon">
                                <img src={im3} alt="im3" />

                            </div>
                            <h2 className="tour">TOURNAMENT</h2>
                        </div>
                    </div>
                    <div className="game-bo">
                        <div className="icon" onClick={openPopup}>
                            <img src={im4} alt="im4" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="player-id">
                    <div className="id" onClick={openprofile}>
                        <div className="img-id">

                        </div>
                        <div className="plyr-contant">
                            <h3>Player_14535363836</h3>
                            <div className="earn"><img src={chip} alt="chip" /> <p>20,00</p></div>
                            <div className="plyr-rat">
                                <i class="fa-solid fa-star"></i><h6>1</h6><span>65.5%</span>
                            </div>
                        </div>
                    </div>
                    <div className="twitter">
                        <i class="fa-brands fa-twitter"></i>
                    </div>
                </div>
            </div>


            <div className="setting-main">
                <div className="setting">
                    <i class="fa-solid fa-gear"></i>
                    <div className="se">
                        <i class="fa-solid fa-question"></i>

                    </div>
                    <div className="se2">
                        <i class="fa-solid fa-gear"></i>

                    </div>
                </div>
            </div>

            <div className="services">
                <div className="ser-ic dm">
                    <i class="fa-solid fa-chart-simple"></i>
                </div>
                <div className="ser-icc dm">
                    <i class="fa-solid fa-gamepad"></i>
                </div>
                <div className="ser-iccc dm">
                    <i class="fa-solid fa-bag-shopping"></i>
                </div>
                <div className="ser-iccc sh">
                    <i class="fa-solid fa-chevron-down"></i>
                </div>
                <i class="fa-solid fa-chart-simple i1"></i>
                <i class="fa-solid fa-gamepad i2"></i>
                <i class="fa-solid fa-bag-shopping i3"></i>

            </div>
            <div>


                {isOpen && (
                    <div className="popup1">
                        <div className="popup1-content">
                            <h3>PURCHASE CHIP & GET BEST DEALS!</h3>
                            <i onClick={closePopup} class="fa-solid fa-xmark"></i>

                        </div>
                        <div>
                            <Popup />
                        </div>

                    </div>
                )}
            </div>
            <div>


{open && (
    <div className="popup1">
        <div className="popup1-content">
            <h3>PURCHASE CHIP & GET BEST DEALS!</h3>
            <i onClick={closePopup2} class="fa-solid fa-xmark"></i>

        </div>
        <div>
            {/* <Popup /> */}
            
        </div>

    </div>
)}

{profile && (
    <div className="popup1">
        <div className="">
          
            <i onClick={closeprofile} class="fa-solid fa-xmark xyz"></i>

        </div>
        <div>
            <ProfilePop/>
        </div>

    </div>
)}

</div>

        </div>



    );
}

export default Home;