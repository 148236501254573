import React from "react";
import '../Home.css'
function Plyrinfo() {
    return ( 
        <>
          <div className="playr-info">
              <div className="plyr-box">
                <div className="plyr-inner">
                  <div className="plyr-img">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <div className="playr-id">
                    <h3>Player_124255</h3>
                    <p>24,299</p>
                  </div>
                </div>
              </div>
              <div className="plyr-bottom">
                <p>YOUR SESSION STATS</p>
              </div>
            </div>
        </>
     );
}

export default Plyrinfo;