import React from "react";
import '../Home.css'
import { Link } from "react-router-dom";
function Luckybord() {
    return ( 
        <>
         <div className="lucky-bord">
              <div className="lucy-bord-main">
                <div className="lucky-head">
                  <h6>HOW LUCKY ARE LAST FIVE NUMBERS?</h6>
                </div>
                <div className="bord-inner">
                  <div className="bord-percen">
                    <div className="border-label">
                      <span>8</span>
                      <div className="label-per">
                        <p>3.5%</p>
                      </div>
                    </div>
                    <div className="border-label">
                      <span style={{ color: "#01af00" }}>17</span>
                      <div className="label-per fdf">
                        <p>7.0%</p>
                      </div>
                    </div>
                    <div className="border-label">
                      <span>3</span>
                      <div className="label-per fdf2">
                        <p>8.8%</p>
                      </div>
                    </div>
                    <div className="border-label">
                      <span>24</span>
                      <div className="label-per fdf3">
                        <p>7.0%</p>
                      </div>
                    </div>
                    <div className="border-label ">
                      <span>27</span>
                      <div className="label-per fdf3">
                        <p>7.0%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bord-btn">
                  <Link to="/stastic">
                    <button>FULL STASTISTICS</button>
                  </Link>
                  <button className="htg">STARTS</button>
                </div>
              </div>
            </div>
        </>
     );
}

export default Luckybord;
